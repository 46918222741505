<script setup>
const props = defineProps({
    data: Object,
    gray: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    }
})

const title = computed(() => {
    if (props.data && props.data.title) {
        return props.data.title;
    }
    return props.data?.header || '';
});
const content = computed(() => props.data.content);
</script>

<template>
    <div :class="{'p-6 rounded bg-gray-100': gray}">
        <template v-if="title">
            <h3 v-if="small"
                class="font-normal mb-2"
                v-html="title"></h3>
            <h2 v-else
                class="font-normal mb-2 text-lg"
                v-html="title"></h2>
        </template>
        <div class="font-extralight" v-html="content"></div>
    </div>
</template>

<style lang="css" scoped>
:deep(p) {
    @apply font-extralight py-2;
}

:deep(a) {
    @apply underline text-azure;
}

:deep(ul) {
    @apply list-disc list-inside;
}

:deep(li) {
    @apply ml-6;
}


</style>
